import React from "react";
import styled from "styled-components";
import tw from "twin.macro";
import { ReactComponent as SvgDotPatternIcon } from "../../images/dot-pattern.svg";
import { SectionHeading as HeadingTitle } from "../misc/Headings.js";
import imgclothbrand from "../../images/cloth-brands.png";
import imgCustomerReview from "../../images/customer-review-1.jpg";

const Container = tw.div`relative`;

const SingleColumn = tw.div`max-w-screen-xl mx-auto py-20 lg:py-24`;

const HeadingInfoContainer = tw.div`flex flex-col items-center`;
const HeadingDescription = tw.p`mt-4 font-medium text-gray-600 text-center max-w-sm`;

const Content = tw.div`mt-0`;

const Card = styled.div(props => [
  tw`mt-4 md:flex justify-center items-center`,
  props.reversed ? tw`flex-row-reverse` : "flex-row"
]);
const Image = styled.div(props => [
  `background-image: url("${props.imageSrc}"); background-repeat: no-repeat;`,
  tw`rounded md:w-1/2 lg:w-5/12 xl:w-2/3 h-128 md:h-80 bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
]);

// const Image = styled.div(props => [
//   `background-image: url("${props.imageSrc}"); background-repeat: no-repeat;`,
//   tw`rounded md:w-1/2 lg:w-5/12 xl:w-1/3 flex-shrink-0 h-80 md:h-80 bg-contain bg-center mx-4 sm:mx-8 md:mx-4 lg:mx-8`
// ]);

const Details = tw.div`mt-4 md:mt-0 md:max-w-md mx-4 sm:mx-8 md:mx-4 lg:mx-8`;
const Subtitle = tw.div`font-bold tracking-wide text-secondary-100`;
const Title = tw.h4`text-3xl font-bold text-gray-900`;
const Description = tw.p`mt-2 leading-loose`;
const Link = tw.a`inline-block mt-4 text-primary-500 font-bold cursor-pointer transition duration-300 border-b-2 border-transparent hover:border-primary-500`;

const SvgDotPattern1 = tw(
  SvgDotPatternIcon
)`absolute top-0 left-0 transform -translate-x-20 rotate-90 translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern2 = tw(
  SvgDotPatternIcon
)`absolute top-0 right-0 transform translate-x-20 rotate-45 translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern3 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 left-0 transform -translate-x-20 rotate-45 -translate-y-8 -z-10 opacity-25 text-primary-500 fill-current w-24`;
const SvgDotPattern4 = tw(
  SvgDotPatternIcon
)`absolute bottom-0 right-0 transform translate-x-20 rotate-90 -translate-y-24 -z-10 opacity-25 text-primary-500 fill-current w-24`;

const HighlightedText = tw.span`bg-blue-300 text-white px-4 transform -skew-x-12 inline-block`;
const HighlightedText2 = tw.span`bg-gray-500 text-white px-4 transform -skew-x-12 inline-block`;

export default () => {
  const cards = [
    {
      imageSrc: imgclothbrand,
      subtitle: "",
      title: <HighlightedText>New But Strong</HighlightedText>,
      description:
        "We proudly present famous Thai brands such as Snow Owl, Iflinbaby. Eventhough, you have not heared them before. They are are well know in Thailand and could guarantee their quality.",
      url: ""
    },
    {
      imageSrc: imgCustomerReview,
      subtitle: "",
      title: <HighlightedText2>Satisfied Customer Feedback</HighlightedText2>,
      description:
        "Every imported products were concerned about practical usages. That's why we really take into account what are customer feelings? Our company only select high positive feedback from origin country to sell them here.",
      url: ""
    }
  ];

  return (
    <Container id="mumlove" >
      <SingleColumn>
        <HeadingInfoContainer>
          <HeadingTitle>Explore MUMLOVE's store</HeadingTitle>
          <HeadingDescription>
            Dive into variety of your lover ones' suitable products from burps, swaddles to pillows.
          </HeadingDescription>
        </HeadingInfoContainer>

        <Content>
          {cards.map((card, i) => (
            <Card key={i} reversed={i % 2 === 1}>
              <Image imageSrc={card.imageSrc} />
              <Details>
                {/* <Subtitle>{card.subtitle}</Subtitle> */}
                <Title>{card.title}</Title>
                <Description>{card.description}</Description>
                {/* <Link href={card.url}>See Event Details</Link> */}
              </Details>
            </Card>
          ))}
        </Content>
      </SingleColumn>
      <SvgDotPattern1 />
      <SvgDotPattern2 />
      <SvgDotPattern3 />
      <SvgDotPattern4 />
    </Container>
  );
};
