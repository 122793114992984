import React,  { useEffect, useState } from "react";
import tw from "twin.macro";
import { css } from "styled-components/macro"; //eslint-disable-line
import Hero from "components/hero/TwoColumnWithVideo.js";
import Features from "components/features/ThreeColSimple.js";
import MainFeature from "components/features/TwoColWithButton.js";
import TabGrid from "components/cards/TabCardGrid.js";
import VerticalWithAlternateImageAndText from "components/features/VerticalWithAlternateImageAndText.js";
import Footer from "components/footers/FiveColumnWithInputForm.js";
import celebrationIconImageSrc from "images/celebration-icon.svg";
import { ProductAPI } from "api/ProductsService"
import Header from "../components/headers/light.js";
import imgStoreExample from "../images/store-example.jpg";
import imgSuperMarket from "../images/grocery-asia-1.jpg";
import imgDD24Logo from "../images/dd24_logo.png";
import imgMumLoveLogo from "../images/mumlove-logo.png";
import SupportIconImage from "../images/support-icon.svg";
import ShieldIconImage from "../images/shield-icon.svg";
import PortfolioTwoCardsWithImage from "components/cards/PortfolioTwoCardsWithImage.js";

export default () => {
  const Subheading = tw.span`tracking-wider text-sm font-medium`;
  const HighlightedText = tw.span`bg-orange-500 text-gray-100 px-4 transform -skew-x-12 inline-block`;
  const HighlightedTextInverse = tw.span`bg-gray-100 text-orange-500 px-4 transform -skew-x-12 inline-block`;
  const HighlightSubtitle = tw.span`text-xl text-orange-500`;
  const TextOrange = tw.span`text-orange-500`;
  const TextTeal = tw.span`text-teal-500`;
  const TextGray = tw.span`text-blue-500`;
  const Description = tw.span`inline-block mt-8`;
  
  const imageCss = tw`rounded-4xl`;


  const [setProducts] = useState();

  useEffect(() => {
    ProductAPI.getAll().then((products) => {
      // response handling
      console.log(JSON.stringify(products));
      setProducts(products);
    })
  }, [])

  return (
    <>
      <Header />
      <PortfolioTwoCardsWithImage 
        subheading = "Our Current Brands"
        headingHtmlComponent = {(
          <>
            We've done 2 retail brands <TextOrange>Asian Grocery</TextOrange> & <TextGray>Baby Cloths</TextGray>
          </>
        )}
        description = ""
        linkText = ""
        cardLinkText = "Visit Website"
        textOnLeft = {true}
        cards = {[
          {
            imageSrc: imgMumLoveLogo,
            company: "MUMLOVE",
            type: "Baby Cloths",
            title: "Present natural-made baby cloths and other accessories for comfort & sensitive skin",
            durationText: "",
            locationText: "",
            href: "https://www.mumlove.com"
          },
          {
            imageSrc: imgDD24Logo,
            company: "DD24",
            type: "Grocery Store",
            title: "Premium Asian Grocery Store with high quality and elaborately collection process",
            durationText: "",
            locationText: "",
            href: "https://www.dd24shop.com"
          }
        ]}
      />
      <VerticalWithAlternateImageAndText />
      <Hero
        heading={<>Our Online Store <HighlightedText>DD24</HighlightedText></>}
        description= { <Description>All "Asian" product you have never tried and be unable to find in Dubai will be served here.
          Provide wide ranges of categories including
          <ul>
            <li>Snack</li>
            <li> - Dessert</li>
            <li> - Fresh fruit & vegetable</li>
            <li> - Dried fruit</li>
            <li> - Cooking ingredient</li>
            <li> - Deli products</li>
            <li> - and more.</li>
          </ul>
          <br/>The stores will be available on physical address soon.<br/>Stay in touch with us!</Description>}
        imageSrc={imgStoreExample}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        primaryButtonText="Learn more"
        watchVideoButtonText="See how our products fit you."
      />
      <MainFeature
        subheading={<Subheading>Established Since 2023</Subheading>}
        heading={
          <>
            What We Want To Be
          </>
        }
        description={
          <Description>
            Our business is not only focus for Asian living in GCC but we are trying to expand Asian eating culture across GCC and MENA.
            <br />
            By establishing the company, it allows us to import our experienced & maintain quality products to customers.
          </Description>
        }
        buttonRounded={false}
        textOnLeft={false}
        primaryButtonText="Latest Offers"
        imageSrc={imgSuperMarket}
        imageCss={imageCss}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 -translate-x-1/2 md:w-32 md:h-32 opacity-25`}
      />
      {/* TabGrid Component also accepts a tabs prop to customize the tabs and its content directly. Please open the TabGrid component file to see the structure of the tabs props.*/}
      {/* <TabGrid
        heading={
          <>
            Checkout our <HighlightedText>products.</HighlightedText>
          </>
        }
        tabs = {
          {
            'Example': products
          }
        }
      /> */}
      <Features
        heading={
          <>
            Our Key <HighlightedText>Focus</HighlightedText>
          </>
        }
        cards={[
          {
            imageSrc: celebrationIconImageSrc,
            title: "Satisfaction",
            description: "Nothing better than happy customer. Seemless customer journey from online to offline.",
            url: ""
          },
          {
            imageSrc: ShieldIconImage,
            title: "Quality",
            description: "Maintain high quality for goods from our pre-selection processes.",
            url: ""
          },
          {
            imageSrc: SupportIconImage,
            title: "Service",
            description: "Our staff is happy to give advice 24/7 through online channel and fast delivery method.",
            url: ""
          }
        ]}

        imageContainerCss={tw`p-2!`}
        imageCss={tw`w-20! h-20!`}
      />
      {/* <MainFeature2
        subheading={<Subheading>A Reputed Brand</Subheading>}
        heading={<>Why <HighlightedText>Choose Us ?</HighlightedText></>}
        statistics={[
          {
            key: "Orders",
            value: "94000+",
          },
          {
            key: "Customers",
            value: "11000+"
          },
          {
            key: "Chefs",
            value: "1500+"
          }
        ]}
        primaryButtonText="Order Now"
        primaryButtonUrl="https://order.now.com"
        imageInsideDiv={false}
        imageSrc="https://images.unsplash.com/photo-1414235077428-338989a2e8c0?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEzNzI2fQ&auto=format&fit=crop&w=768&q=80"
        imageCss={Object.assign(tw`bg-cover`, imageCss)}
        imageContainerCss={tw`md:w-1/2 h-auto`}
        imageDecoratorBlob={true}
        imageDecoratorBlobCss={tw`left-1/2 md:w-32 md:h-32 -translate-x-1/2 opacity-25`}
        textOnLeft={true}
      /> */}
      {/* <Testimonial
        subheading=""
        heading={<>Customers <HighlightedText>Love Us.</HighlightedText></>}
      /> */}
      {/* <DownloadApp
        text={<>Explore and order our best products for you anywhere, anytime on your own pace, download <HighlightedTextInverse>DD24</HighlightedTextInverse>
        <br/><br/><HighlightSubtitle>*Our apps will be available soon on iOS & Android</HighlightSubtitle></>}
      /> */}
      <Footer />
    </>
  );
}
