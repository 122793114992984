
import { api } from "./axiosConfig"
import { defineCancelApiObject } from "./axiosUtils"

export const ProductAPI = {
  get: async function (id, cancel = false) {
    const response = await api.request({
      url: `/getproducts/:id`,
      method: "GET",
      // retrieving the signal value by using the property name
      signal: cancel ? cancelApiObject[this.get.name].handleRequestCancellation().signal : undefined,
    })

    // returning the product returned by the API
    return response.data.response
  },
  getAll: async function (cancel = false) {
    const response = await api.request({
      url: "/getproducts",
      method: "GET",
      signal: cancel ? cancelApiObject[this.getAll.name].handleRequestCancellation().signal : undefined,
    })

    return response.data.response
  },
}

// defining the cancel API object for ProductAPI
const cancelApiObject = defineCancelApiObject(ProductAPI)